<template>
  <div class="addressBook">
    <div class="searchBox">
      <!-- 加载中 -->
      <van-overlay :show="loadingShow">
        <van-loading
          text-color="#0094ff"
          color="#0094ff"
          vertical
        >加载中...</van-loading>
      </van-overlay>
      <form action="/">
        <van-search
          v-model="userName"
          show-action
          placeholder="请输入人员姓名"
          @input="onInput"
        />
      </form>
    </div>
    <div
      class="userAddressList"
      v-if="indexList.length"
      ref="userAddressList"
      @scroll="onScroll"
    >
      <van-index-bar :index-list="indexList">
        <div
          v-for="index in indexList"
          :key="index"
        >
          <van-index-anchor :index="index">
            {{ index }}
          </van-index-anchor>
          <van-cell
            :title="item.name"
            v-for="(item, key) in userAddress[index]"
            :key="item.id + key"
            @click="onClick(item.id)"
          />
        </div>
      </van-index-bar>
      <p class="total">共{{ total }}人</p>
    </div>
    <div
      class="empty"
      v-else
    >
      <van-empty
        image="search"
        description="没有符合条件的联系人"
      />
    </div>
  </div>
</template>

<script>
import { getUserAddress } from '@/api/addressBook'
let timeId = null
export default {
  name: 'addressBook',
  // created () {
  //   if (JSON.stringify(this.userAddress) === '{}') {
  //     this.getUserAddress()
  //   }
  // },
  data() {
    return {
      userName: '',
      indexList: [],
      userAddress: {},
      top: 0,
      total: 0,
      loadingShow: false
    }
  },

  activated() {
    if (this.$refs.userAddressList) {
      this.$refs.userAddressList.scrollTop = this.top
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.path.includes('/personalAddressInfo')) {
      next((vm) => {
        if (JSON.stringify(vm.userAddress) === '{}') {
          vm.getUserAddress()
        }
      })
    } else {
      next((vm) => {
        vm.getUserAddress()
        vm.userName = ''
      })
    }
  },
  beforeRouteLeave(to, from, next) {
    clearTimeout(timeId)
    next()
  },
  methods: {
    onInput(val) {
      clearTimeout(timeId)
      timeId = setTimeout(async () => {
        this.loadingShow = true
        try {
          const { data } = await getUserAddress({ userName: this.userName })
          this.indexList = Object.keys(data.data.initials)
          this.userAddress = data.data.initials
          this.total = data.data.total
          if (this.$refs.userAddressList) {
            this.$refs.userAddressList.scrollTop = 0
          }
          this.loadingShow = false
        } catch (error) {
          this.loadingShow = false
          if (error?.response?.status === 401) {
            return
          }
          if (error.message.includes('timeout')) {
            this.$toast.fail({
              message: '网络不好，请稍后再试!',
              duration: 500
            })
            return
          }
          this.$toast.fail({
            message: '通讯录获取失败,请稍后再试!',
            duration: 500
          })
        }
      }, 300)
    },
    onClick(id) {
      this.$router.push({
        path: `/personalAddressInfo/${id}`
      })
    },
    onScroll(e) {
      this.top = e.target.scrollTop
    },
    // 获取人员通讯录
    async getUserAddress() {
      this.loadingShow = true
      try {
        const { data } = await getUserAddress()
        this.indexList = Object.keys(data.data.initials)
        this.userAddress = data.data.initials
        this.total = data.data.total
        if (this.$refs.userAddressList) {
          this.$refs.userAddressList.scrollTop = 0
        }
        this.loadingShow = false
      } catch (error) {
        this.loadingShow = false
        if (error?.response?.status === 401) {
          return
        }
        if (error.message.includes('timeout')) {
          this.$toast.fail({
            message: '网络不好，请稍后再试!',
            duration: 500
          })
          return
        }
        this.$toast.fail({
          message: '通讯录获取失败,请稍后再试!',
          duration: 500
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.addressBook {
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  ::v-deep {
    .van-index-anchor {
      left: 0 !important;
    }

    .van-overlay {
      top: 100px;
      bottom: 50px;
      height: auto;

      .van-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .van-index-anchor {
      left: 0 !important;
    }

    .searchBox {
      position: relative;
      z-index: 2;
    }

    .userAddressList {
      height: calc(100% - 54px);
      overflow: auto;
      padding-top: 1px;

      .van-index-anchor {
        font-weight: 700;
      }

      .total {
        text-align: center;
        font-size: 14px;
        color: #aaaaaa;
        padding-bottom: 10px;
      }
    }

    .empty {
      height: calc(100% - 54px);
      position: relative;

      .van-empty {
        position: absolute;
        width: 100%;
        transform: translateY(-50%);
        top: 50%;
      }
    }
  }
}
</style>
